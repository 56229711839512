<template>
    <AppLoadingWrapper v-if="loading" />
    <AppPaginatedGrid
        @onAfterDelete="onAfterDelete"
        permission="configuracoes_empresa"
        :expander="false"
        :service="service"
        newPath="/companies/new"
        editPath="/companies/"
        subtitle="Empresa"
        tooltip="Cadastro de bases"
        :extraActionItems="extraActionItems"
        @onToggleMenu="onToggleMenu"
        nomeTelaDoManual="companies-list"
        origemHistoricoAcao="/companies"
    >
        <template #columns>
            <Column field="name" :sortable="true" header="Nome"></Column>
            <Column field="tradeName" :sortable="true" sortField="trade_name" header="Razão social"></Column>
        </template>
    </AppPaginatedGrid>

    <AppAnexoDialog
        v-if="showAnexoDialogManipulacao"
        v-model:visible="showAnexoDialogManipulacao"
        title="Carteira de Manipulação de Alimentos"
        tipo="MANIPULACAO_ALIMENTOS"
        origem="EMPRESA_MANIPULACAO_ALIMENTOS"
        v-model:origemId="actionRecord.id"
        @onClickCloseAnexo="showAnexoDialogManipulacao = false"
    >
    </AppAnexoDialog>

    <AppAnexoDialog
        v-if="showAnexoDialogIncidentes"
        v-model:visible="showAnexoDialogIncidentes"
        title="Avaliação de ocorrências no trabalho"
        tipo="INCIDENTES_DE_TRABALHO"
        origem="INCIDENTES_DE_TRABALHO"
        v-model:origemId="actionRecord.id"
        @onClickCloseAnexo="showAnexoDialogIncidentes = false"
    >
    </AppAnexoDialog>

    <AppAnexoDialog
        v-if="showAnexoDialogsPic"
        v-model:visible="showAnexoDialogsPic"
        title="Protocolo de Interpretação e Condutas Médicas"
        tipo="PROTOCOLO_PIC"
        origem="PROTOCOLO_PIC"
        v-model:origemId="actionRecord.id"
        @onClickCloseAnexo="showAnexoDialogsPic = false"
    >
    </AppAnexoDialog>
</template>

<script>
import BaseService from '../../services/BaseService';
import EventBus from '../../event-bus';

export default {
    data() {
        return {
            service: null,
            loading: false,
            showAnexoDialogManipulacao: false,
            showAnexoDialogIncidentes: false,
            showAnexoDialogsPic: false,
            actionRecord: {},
            extraActionItems: [
                {
                    label: 'Carteira de Manipulação de Alimentos',
                    icon: 'pi pi-paperclip',
                    command: () => this.anexosCarteiraManipulacaoAlimentos(this.actionRecord)
                },
                {
                    label: 'Avaliação de ocorrências no trabalho',
                    icon: 'pi pi-paperclip',
                    command: () => this.anexosIncidentesDeTrabalho(this.actionRecord)
                },
                {
                    label: 'Protocolo de Interpretação e Condutas Médicas',
                    icon: 'pi pi-paperclip',
                    command: () => this.anexosPic(this.actionRecord)
                }
            ],
        };
    },
    created() {
        this.loading = true;
        this.service = new BaseService('/companies');
        this.loading = false;
    },
    methods: {
        anexosCarteiraManipulacaoAlimentos() {
            this.showAnexoDialogManipulacao = true;
        },
        anexosIncidentesDeTrabalho() {
            this.showAnexoDialogIncidentes = true;
        },
        anexosPic() {
            this.showAnexoDialogsPic = true;
        },
        async onAfterDelete() {
            EventBus.emit('empresa-excluida');
        },
        onToggleMenu(event, data) {
            this.actionRecord = data;
        }
    }
};
</script>
